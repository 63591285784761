import React from "react"
import { Link } from "gatsby"
import SEO from "../components/shared/seo"

import "./css/work.css"

const IndexPage = () => (
  <React.Fragment>
    <SEO title="Home" keywords={[`gatsby`, `application`, `react`]} />
    <section
      className="bd-index-fullscreen hero"
      style={{ minHeight: "inital !important" }}
    >
      <div className="hero-body">
        <header className="">
          <h1 className="">
            <span id="">Benedict Chachaj</span>
          </h1>
          <h4
            id="brand-subhead"
            className="accent-muted"
            style={{ fontWeight: "400", color: "lightgray" }}
          >
            Front End Software Engineer
          </h4>
          <p className="animated animatedFadeInUp fadeInUp">
            Please direct business and personal inquires to my&nbsp;
            <a
              className="accent-color index-email"
              href="mailto:ben.chachaj@gmail.com?subject=Hello!"
            >
              email
            </a>
            .
          </p>

          <div className="leveled flexed">
            <span className="leveled-item flexed">
              <Link to="/about" className="index-link link">
                {" "}
                About
              </Link>
              <p>&nbsp;&nbsp;|&nbsp;&nbsp;</p>
            </span>
            <span className="leveled-item flexed">
              <Link to="/work" className="index-link link">
                {" "}
                Work
              </Link>
              <p>&nbsp;&nbsp;|&nbsp;&nbsp;</p>
              {/* <a href="/myWork" className="index-link link"> Work</a><p>&nbsp;&nbsp;|&nbsp;&nbsp;</p> */}
            </span>
            <span className="leveled-item flexed">
              <Link to="/blog" className="index-link link">
                {" "}
                Writing{" "}
              </Link>
            </span>
          </div>
        </header>
      </div>
    </section>
  </React.Fragment>
)

export default IndexPage
